import { Select } from 'antd';
import { useEffect, useState } from 'react';
import useClients, { IClient } from '../hooks/useClients';

const { Option } = Select;

export default (props: {
  mode?: 'multiple' | 'tags';
  value?: any;
  applicationId: string;
  readOnly?: boolean;
  className?: string;
  loading?: boolean;
  valueIndex?: keyof IClient;
  onChange?: (value: string, id: string) => void;
  renderOptions?: (options: any[]) => { label: string; value: string; disabled?: boolean }[];
  currentClient?: (currentClient: IClient | null) => void;
}) => {
  const {
    mode,
    readOnly = false,
    className = '',
    applicationId,
    value,
    valueIndex = 'id',
    onChange = () => undefined,
    renderOptions,
  } = props;
  const [clients, setClients] = useState<{ label: string; value: string; id: string }[]>([]);
  const { data, getAll, loading } = useClients();

  const [ClientsObjects, setClientsObjects] = useState<IClient[]>([]);
  const [selectedClient, setSelectedClient] = useState<IClient>();

  useEffect(() => {
    if (value && clients.length) {
      setSelectedClient(ClientsObjects.find((Client: IClient) => Client[valueIndex || 'id'] === value));
    }
  }, [clients]);

  useEffect(() => {
    if (props?.currentClient && selectedClient) {
      props?.currentClient(value ? selectedClient : null);
    }
  }, [!!selectedClient, value]);

  useEffect(() => {
    applicationId && value && getAll(applicationId, true);
  }, [value, applicationId]);

  useEffect(() => {
    const items: IClient[] = data?.items || [];
    setClientsObjects(items);
    setClients(items.map((client: any) => ({ label: client.label, value: client[valueIndex], id: client.id })));
  }, [data, valueIndex]);

  // When onChange is called, the selected value and its id is passed to the onChange function
  return (
    <Select
      {...{ mode }}
      allowClear
      value={value}
      className={className}
      showSearch
      loading={loading}
      disabled={readOnly}
      style={{ width: '100%' }}
      placeholder={!readOnly && 'Search and Select a Client'}
      onChange={(value) => onChange(value, clients.find((Client) => Client.value === value)?.id || '')}
      options={clients}
      filterOption={(inputValue, option) => {
        const label: string = option?.label as string;
        const labelUp: string = label?.toUpperCase() || '';
        return labelUp?.indexOf(inputValue.toUpperCase()) !== -1;
      }}
    >
      {renderOptions &&
        renderOptions(clients || []).map((item) => (
          <Option key={item.value} value={item.value} disabled={item.disabled || false}>
            {item.label}
          </Option>
        ))}
    </Select>
  );
};
